<template>
  <div id="app">
    <div class="index">
      <div id="main-1">
        <!-- Header -->
        <header id="header">
          <h1 v-if="langcode === 'en'">VIRTUE AND WISDOM EDUCATION CENTER</h1>
          <h1 v-else>德智樂教育中心</h1>
          <ul class="icons">
            <li v-if="langcode === 'en'">{{ $t('Address') }}: FLAT B 1/F, KA WAI COURT, NO.50-52 PAU CHUNG STREET, TO KWA WAN, KOWLOON</li>
            <li v-else>{{ $t('Address') }}: 九龍土瓜灣炮仗街50－52號嘉威閣1樓B室</li>
            <li>{{ $t('Email') }}: virtuenwisdom@outlook.com</li>
            <li>{{ $t('Tel') }}: 852-69994544</li>
          </ul>
          <a href="#" @click="viewAboutUs">
            {{ $t('About Us')}}
          </a>
        </header>

        <!-- Footer -->
          <footer id="footer">
            <a @click="selectLang('zh')" v-if="langcode === 'en'" href="#">中文</a>
            <a @click="selectLang('en')" v-if="langcode === 'zh'" href="#">EN</a>
            <ul class="copyright">
              <li>&copy; VIRTUE AND WISDOM EDUCATION CENTER</li>
            </ul>
          </footer>
          <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/01.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

    <div class="about-us">
      <div id="main-2">
        <!-- Header -->
        <header id="header">
          <h1>{{ $t('About Us') }}</h1>
          <div class="description" v-if="langcode == 'en' ">
            We have a team of professionally trained teachers and counselling staff who provide personalised education and support to ensure that each student gets the most out of their education. In addition to providing curriculum instruction, we work with parents and students to form a collaborative teaching model to ensure that each student receives the best possible support in their learning process.
          </div>
          <div class="description" v-if="langcode == 'zh' ">
            我們有一群經過專業訓練的教師及輔導工作人員，他們對學生提供個性化的教育與支持，以確保每個學生都能從中獲得最大的收獲。除了提供課程教學外，我們還會與家長及學生合作，形成一個協作的教學模式，確保每個學生在學習過程中可以得到最佳的扶持。
          </div>
          <a href="#" @click="backIndex">
            {{ $t('Back to front page')}}
          </a>
        </header>
        <VueBotUI
        :messages="data"
        @msg-send="messageSendHandler"
        />
      </div>
      <div id="viewer">
        <div class="inner">
          <div class="nav-next"></div>
          <div class="nav-previous"></div>
          <div class="toggle"></div>
        </div>
        <div class="slide active">
          <div class="caption"></div>
          <div class="image" style="background-position: left center; background-image: url(&quot;images/fulls/02.jpg&quot;);">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { VueBotUI } from 'vue-bot-ui';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  mounted(){
    const langcode = Cookies.get('langcode');
    if(langcode){
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    }
    this.loadWebsiteContent();
  },
  components: {
    VueBotUI,
  },
  data() {
    return {
      data: [
        {
          agent: 'bot',
          type: 'button',
          text: 'Hello, Welcome to 德智樂教育中心. How can I help you? ',
          disableInput: true,
          options: [
            {
              text: 'Your business hours is ?',
              value: 'business_hour',
              action: 'postback' // Request to API
            },
            {
              text: 'Any contact method ?',
              value: 'place_order',
              action: 'postback' // Request to API
            },
          ],
        }
      ],
      replyTexts: [
        {
          'question': 'business_hour',
          'answer': 'Our business hours is 10:00 - 17:00.',
        },
        {
          'question': 'place_order',
          'answer': 'Please feel free to contact with us by mail or phone: virtuenwisdom@outlook.com / 852-69994544',
        },
      ],
      name: '',
      descEn: '',
      descZh: '',
      contact: '',
    }
  },
  methods:{
    viewAboutUs(){
      const index = document.querySelector('.index');
      index.style.display = 'none';
      const about = document.querySelector('.about-us');
      about.style.display = 'block';
    },
    backIndex(){
      const index = document.querySelector('.index');
      index.style.display = 'block';
      const about = document.querySelector('.about-us');
      about.style.display = 'none';
    },
    async loadConfig(){
      return new Promise(async(resolve, reject) => {
        const baseApiUrl = process.env.VUE_APP_API_URL;
        const apiUrl = `${baseApiUrl}/website/content`;
        const fetchResult = await fetch(apiUrl, {
          method: 'GET',
          headers: {
           'Content-type': 'application/json; charset=UTF-8'
          },
        });

        const status = await fetchResult.status;

        //If cannot connect with API server
        if(status !== 200 && status !== 401){
          const err = 'Cannot load system config. Maybe there are some network problem?';
          reject(err);
          return;
        }

        //If user do not login or no permission
        if(status === 401){
          const err = 'Sorry, You don\'t had permission to access it.';
          reject(err);
          return;
        }

        const resultJson = await fetchResult.json();

        resolve(resultJson);
      });
    },
    async loadWebsiteContent(){
      try{
        let websiteContent = await this.loadConfig();

        this.name = websiteContent['website_name'];
        this.descZh = websiteContent['website_introduce_zh'];
        this.descEn = websiteContent['website_introduce_en'];
        this.contact = websiteContent['company_contact'];

      }catch(err){
        console.log(err);
      }
    },
    selectLang(langcode){
      Cookies.set('langcode', langcode);
      this.$i18n.locale = langcode;
      this.changeLang(langcode);
      document.title = this.$t('Page Title');
    },
    messageSendHandler(userAction){
      const question = userAction['value'];
      let answer = this.replyTexts.find(function(item){
        return item.question === question
      });
      answer = answer['answer'];
      const reply = {
        agent: 'bot',
        type: 'text',
        text: answer,
        disableInput: false,
      }
      this.data.push(reply);
    },
    ...mapActions(['changeLang']),
  },
  computed: mapState({
   langcode: state => state.langcode,
 }),
}
</script>

<style lang="scss">
h1{
  text-align: left;
}
body.is-preload-1 #main{
  opacity: 1;
  right: 0;
}
.qkb-board-action{
  display: none;
}
#viewer .slide .image{
  @media screen and (min-width: 1300px){
    background-size: 100%;
  }
}
.about-us{
  display: none;
}
.description{
  text-align: left;
  margin-bottom: 20px;
}
</style>
